import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useHistory from React Router
import './WatchCardComponent.css'; // Create a CSS file for styling
import DomainUtils from '../../services/DomainUtils';

const WatchCardComponent = ({ watchSnippet, onSelectAnime, preview = false }) => {

    const [imageLoaded, setImageLoaded] = useState(false);
    const navigate = useNavigate();

    const [hideSeriesPreviewEnabled, setHideSeriesPreview] = useState(localStorage.getItem("hideSeriesPreview"));

    const handleImageLoad = () => {
        setImageLoaded(true);
    };


    const handleSeriesClick = () => {
        // Navigate to /anime/{id} when the button is clicked
        navigate(`/watch/${watchSnippet.animeId}/${watchSnippet.seriesId}`);
        if (onSelectAnime != null)
            onSelectAnime(watchSnippet.seriesId);
    };
    const formatTime = (timeInSeconds) => {

        const minutes = Math.floor(timeInSeconds / 60);
        const seconds = Math.floor(timeInSeconds % 60);
        return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };

    const API_DOMAIN = window.location.hostname === 'localhost'
        ? 'https://api.animix.club'
        : `https://api.${DomainUtils.getDomain()}`;

    return (
        <div
            className={`watch-card ${preview ? "preview" : ""}`}
            onClick={handleSeriesClick}
        >

            <img
                src={`${API_DOMAIN}/anime/getThumb/${watchSnippet.privateVideoId}_thumb.png`}
                alt={watchSnippet.animeName}
                className={`series-thumbnail ${imageLoaded ? 'loaded' : ''} ${hideSeriesPreviewEnabled != null ? (hideSeriesPreviewEnabled === "true" ? 'blur' : '') : ''}`}
                onLoad={handleImageLoad}
            />
            <p className={`time-watch-card`}>{watchSnippet.watched ? "Просмотрено" : formatTime(watchSnippet.timeSec)}</p>

            <div className='watch-card-text-block'>

                <p className={`series-subname active`}>{watchSnippet.series} серия, {watchSnippet.season} сезон</p>
                <h4 className={`series-name ${preview ? "preview" : ""}`}>{watchSnippet.animeName}</h4>

            </div>




        </div>
    );
};

export default WatchCardComponent;

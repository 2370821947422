import React, { useState, useEffect } from 'react';
import './../../App.css'; // Assuming you have a CSS file for styling
import './WatchPage.css'; // Assuming you have a CSS file for styling
import { useParams, useNavigate } from 'react-router-dom';

// Import logo.svg from the assets folder
import Header from '../../components/Header';
import PlayIcon from '../../assets/ic_play.svg';
import Footer from '../../components/Footer';
import video from './../../assets/output.mp4';
import AnimeCardComponent from '../../components/animecard/AnimeCardComponent';
import { DetectAdblock } from '@scthakuri/adblock-detector';
import ApiService from '../../services/ApiService';
import DomainUtils from '../../services/DomainUtils';
import SeasonComponent from '../../components/SeasonComponents';
import { wait } from '@testing-library/user-event/dist/utils';
import VideoPlayer from '../../components/player/VideoPlayer';
import userEvent from '@testing-library/user-event';
import WatchRoom from '../../components/player/socket/WatchRoom';
import YandexAdComponent from '../../components/YandexAdComponent';
import '../../components/player/socket/WebSocketService';
import { useDetectAdBlock } from "adblock-detect-react";
import { sendSeriesChange } from '../../components/player/socket/WebSocketService';
import AnimixPremiumPopup from '../../components/premium/AnimixPremiumPopup';
import AppPromo from '../../components/AppPromo';
import AdBlockWarn from '../../components/adblock/AdBlockWarn';

const TestWatchPage = () => {
    var { id, seriesId } = useParams();
    const [scrollPosition, setScrollPosition] = useState(0);
    const [anime, setAnime] = useState([]);
    const [seriesData, setSeriesData] = useState(null);
    const [series, setSeries] = useState(null);
    const [watchUrl, setWatchUrl] = useState(null);
    const [watchTime, setWatchTime] = useState(0);
    const [savedWatchTime, setSavedWatchTime] = useState(1);
    const [reportTime, setReportTime] = useState(Date.now());
    const [seriesCount, setSeriesCount] = useState(0);
    const [nextSeries, setNextSeries] = useState(null);
    const [timeSet, setTimeSet] = useState(false);
    const [adDetected, setAdDetected] = useState(false);
    const [timeFirst, setTimeFirst] = useState(0);
    const [isAfkPopupVisible, setIsAfkPopupVisible] = useState(false); // State for AFK popup visibility
    const [isAvailable, setIsAvailable] = useState(true);
    const navigate = useNavigate();
    const adBlockDetected = useDetectAdBlock();

    const [showSubscribePopup, setShowSubscribePopup] = useState(false);

    useEffect(() => {
        // Calculate series count when seriesData changes
        if (seriesData == null) return;
        let count = 0;
        Object.values(seriesData).forEach(season => {
            count += season.length;
        });
        setSeriesCount(count);
    }, [seriesData]);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };

    const randomId = Math.floor(Math.random() * 1000000);

    useEffect(() => {
        const handleScroll = () => {
            const position = window.pageYOffset;
            setScrollPosition(position);
            resetInactivityTimer(); // Reset timer on scroll
        };

        window.addEventListener('scroll', handleScroll, { passive: true });

        getSeriesData();
        fetchData();
        setupInactivityTimer(); // Setup the inactivity timer
        return () => {
            window.removeEventListener('scroll', handleScroll);
            clearInactivityTimer(); // Clear the timer when the component unmounts
        };
    }, []);

    var fetchedSeries = false;
    const getSeriesData = async () => {
        try {
            let seriesLocData = seriesData;
            if (!fetchedSeries && seriesLocData == null) {
                fetchedSeries = true;
                const data = await ApiService.getSeries(id);
                if (data.type == "error") {
                    setIsAvailable(false);
                    return;
                }
                setNextSeries(null);

                seriesLocData = data.data;
                setSeriesData(data.data);
                scrollToTop();
            }
            for (const seasonNum in seriesLocData) {
                const seritesList = seriesLocData[seasonNum];
                var wasLast = false;
                seritesList.forEach(seriesLoc => {
                    if (seriesLoc.id === parseInt(seriesId)) {
                        setSeries(seriesLoc);
                        fetchVideo(seriesLoc);
                        wasLast = true;
                        return;
                    }
                    if (wasLast) {
                        setNextSeries(seriesLoc);
                        wasLast = false;
                    }
                });
            }
        } catch (error) {
            console.error('Error fetching series data:', error);
        }
    }

    var watchingSessionForSeriesId = -1;

    var fetchedAnime = false;
    const fetchData = async () => {
        try {
            if (!fetchedAnime) {
                fetchedAnime = true;
                const animeData = await ApiService.getAnime(id);
                const blacklisted = await ApiService.isBlacklisted(id);
                if (blacklisted.data == true) {
                    setIsAvailable(false);
                    setSeries(null)
                    setAnime(null)


                    setSeriesData(null)

                }
                if (animeData.type === "success") {
                    setAnime(animeData.data);
                    document.title = "Смотреть " + animeData.data.name + " на animix";
                }
                else {
                    setIsAvailable(false);
                }
            }

        } catch (error) {
            console.error('Error fetching anime list:', error);
        }


    };

    const fetchVideo = async (series) => {

        try {
            if (watchingSessionForSeriesId != seriesId) {
                watchingSessionForSeriesId = seriesId;
                const oldSeriesId = seriesId;

                const optimalServerData = await ApiService.getOptimalServer(series.privateId);
                if (seriesId == oldSeriesId) {

                    
                    const API_DOMAIN = window.location.hostname === 'localhost'
                    ? 'https://cdn.animix.lol'
                    : `https://cdn.${DomainUtils.getDomain()}`;
                    console.log("set watch url")
                    if (optimalServerData.data === "no-cache") {
                        setWatchUrl(`${API_DOMAIN}/content/directWatch?privateId=` + series.privateId);
                    }
                    else {
                        const animeData = await ApiService.getWatchSession(series.privateId, optimalServerData.data);
                        setWatchUrl(optimalServerData.data.replace("animix.lol", DomainUtils.getDomain()) + "content/watch/" + animeData.data);
                    }
                }
                if (localStorage.getItem("sessionKey") != null) {
                    const startWatchData = await ApiService.startWatch(seriesId);
                    if (startWatchData.type !== "success") {
                        console.log('Error starting watch');
                    } else {
                        console.log('Started watching...');
                    }
                }
            }

        } catch (error) {
            console.error('Error fetching anime list:', error);
        }
    };


    useEffect(() => {
        const sendRequest = () => {
            if (Date.now() - reportTime < 25000) return;
            const currentTimeMillis = Date.now();
            setReportTime(currentTimeMillis)
            if (seriesId) {
                try {
                    if (localStorage.getItem("sessionKey") != null) {
                        ApiService.endWatch(seriesId, parseInt(watchTime), localStorage.getItem("sessionKey"));
                    } else {
                        ApiService.endWatch(seriesId, parseInt(watchTime), "unknown" + randomId);
                    }
                } catch (error) {
                    console.error('Error ending watch session:', error);
                }
            }
        };
        sendRequest();
        return () => { };
    }, [watchTime, seriesId]);

    const handleNextButton = () => {
        if (isAfkPopupVisible) return;
        navigate(`/watch/${anime.id}/${nextSeries.id}`);
        seriesId = nextSeries.id;

        getSeriesData();
        handleSelectSeries(nextSeries);
        console.log("trying to show an ad")
        showAd()
    }

    let watchHistorySeriesId = -1;
    useEffect(() => {
        const sendRequest = async () => {
            try {
                if (watchHistorySeriesId != seriesId) {
                    watchHistorySeriesId = seriesId;
                    const oldSeriesId = seriesId;
                    const watchData = await ApiService.getWatchHistory(seriesId);
                    if (watchData.type === "success" && seriesId == oldSeriesId && savedWatchTime == 1 && watchData.data.watchedTimeSec != 0
                        && savedWatchTime != watchData.data.watchedTimeSec) {
                        setSavedWatchTime(watchData.data.watchedTimeSec);
                    }
                }
            } catch (e) {
                console.error('Error fetching watch history:', e);
            }
        };
        sendRequest();
        return () => { };
    }, [seriesId]);

    const updateWatchTime = (time) => {
        setWatchTime(time);
    };

    const handleSelectSeries = (series) => {
        seriesId = series.id;
        scrollToTop();
        setSavedWatchTime(1);
        setWatchUrl(null);
        setSeries(series);
        setTimeSet(false);
        fetchData();
        getSeriesData();
        sendSeriesChange(id, seriesId, localStorage.getItem("nickname"));

    };

    const handleSocketSeriesChange = (message) => {

        if (message.sender == localStorage.getItem("nickname")) return;

        console.log(message.message);
        let content = JSON.parse(message.message);

        seriesId = content.seriesId;
        const series = findSeriesById(seriesId);
        if (series) {
            scrollToTop();
            setSavedWatchTime(1);
            setWatchUrl(null);
            setSeries(series);
            setTimeSet(false);
            fetchData();
            getSeriesData();
        } else {
            console.error(`Series with seriesId ${seriesId} not found`);
        }

    };


    function findSeriesById(seriesId) {
        if (seriesData == null) return;
        for (const seasonNumber in seriesData) {
            const seriesList = seriesData[seasonNumber];
            for (const series of seriesList) {
                if (series.id === seriesId) {
                    return series;
                }
            }
        }
        return null; // Return null if the series is not found
    }

    const setT = (b) => {
        setTimeSet(b)
    };

    useEffect(() => {
        const checkAdBlock = () => {
            if (Date.now() - timeFirst > 10000 && timeFirst != 0) return;
            if (timeFirst == 0) {
                setTimeFirst(Date.now());
            }
            console.log("render " + timeFirst);

            console.log(adBlockDetected + " adblock");
            if (adBlockDetected) {

                setAdDetected(true);
            }
        };

        checkAdBlock();
        return () => {

        };
    }, [adBlockDetected, timeFirst]);




    // useEffect(() => {
    //     const checkAdBlock = () => {
    //         DetectAdblock((detected) => {
    //             if (timeFirst != 0) return;
    //             if (timeFirst == 0) {
    //                 setTimeFirst(Date.now());
    //             }
    //             console.log("render " + timeFirst);

    //             console.log(detected + " adblock");
    //             if (detected) {

    //                 setAdDetected(true);
    //             }

    //         });
    //     };

    //     checkAdBlock();
    //     return () => {

    //     };
    // }, [adBlockDetected, timeFirst]);

    // Inactivity timer setup
    const inactivityTimeLimit = 2 * 60 * 60 * 1000; // 2 hours in milliseconds
    let inactivityTimer;

    const setupInactivityTimer = () => {
        document.addEventListener('mousemove', resetInactivityTimer);
        document.addEventListener('keydown', resetInactivityTimer);
        document.addEventListener('scroll', resetInactivityTimer);
        resetInactivityTimer(); // Initial timer setup
    };

    const clearInactivityTimer = () => {
        clearTimeout(inactivityTimer);
        document.removeEventListener('mousemove', resetInactivityTimer);
        document.removeEventListener('keydown', resetInactivityTimer);
        document.removeEventListener('scroll', resetInactivityTimer);
    };

    const resetInactivityTimer = () => {
        clearTimeout(inactivityTimer);
        inactivityTimer = setTimeout(showAfkPopup, inactivityTimeLimit);
    };

    const showAfkPopup = () => {
        setIsAfkPopupVisible(true);
    };

    const hideAfkPopup = () => {
        setIsAfkPopupVisible(false);
        resetInactivityTimer(); // Reset timer after closing popup
    };

    let isAdShown = false;

    useEffect(() => {
        console.log("trying to show an ad")
        if (!isAdShown) {
            isAdShown = true;
            showAd()
        }

    }, []);


    const showAd = () => {

        console.log("Checking if Ya.Context is available:", window.Ya && window.Ya.Context);

        window.yaContextCb.push(() => {
            console.log("Trying to create ad with Ya.adfoxCode");
            window.Ya.adfoxCode.create({

                containerId: "ety-cloud-player",
                ownerId: "264109",
                params: {
                    p1: "deceb",
                    p2: "jdut",
                },
                onError: () => console.log("Ad Error"),
                onRender: () => console.log("Ad rendered successfully"),
            });
        });

    };

    return (
        <div className="app">
            <Header scrolled={scrollPosition > 0} />
            <div className="solid-back" >
                <div className='video-container2'>

                    {isAvailable ? <VideoPlayer src={watchUrl} series={series} onVideoFinished={handleNextButton} updateWatchTime={updateWatchTime} timeSec={savedWatchTime} timeSet={timeSet} setTimeSet={setT} onSeriesChange={handleSocketSeriesChange}
                        adDetected={adDetected}
                    /> : ''}



                    <div className="ad">
                        <div id="ety-cloud-player" ></div>
                    </div>
                </div>
            </div>
            <div className='anime-half-con'>
                <div className="anime-half">
                    {localStorage.getItem("sessionKey") == null ? (
                        <div className='ad-banner'>
                            <h2 className='small-text'>Хотите сохранить прогресс?</h2>
                            <h3 className='small-text'>Если войдёте в аккаунт, мы запомним на каком моменте вы остановились, какие серии посмотрели, соберём всю статистику по просмотрам и многое другое!</h3>
                        </div>
                    ) : ""}
                    {localStorage.getItem("subscribed") == true ? "" : <YandexAdComponent blockId="R-A-8131234-4" />}

                    <div className='anime-top-con'>
                        <a href={`/anime/${anime.id}`}>
                            <div className='anime-snippet'>
                                <img className='anime-snippet-img' src={`https://api.animix.lol/anime/getThumb/${anime.id}_thumb.png`} alt={`${anime.name} thumbnail`}></img>
                                <h4 className='small-text'>{anime.name}</h4>
                            </div>

                        </a>

                        {localStorage.getItem("subscribed") !== "true" ? <button className='anime-next-series-button' style={{ background: "white", color: "black" }} onClick={() => {
                            if (localStorage.getItem("sessionKey") == null) { navigate("/login"); return; }
                            setShowSubscribePopup(true);
                        }}>Отключить рекламу</button> : ""}
                        {nextSeries != null ? <button className='anime-next-series-button' onClick={handleNextButton}> След. серия</button> : ""}
                    </div>

                    {series ? <h1 className='series-title'>{seriesCount === 1 ? anime.name : `${series.seriesNum} серия, ${series.season} сезон`} </h1> : <p>..</p>}
                    {seriesData ? <SeasonComponent seriesData={seriesData} animeData={anime} onSelectAnime={handleSelectSeries} currentId={seriesId} /> : <p>Загружаем серии...</p>}
                    {localStorage.getItem("subscribed") == true ? "" : <YandexAdComponent blockId="R-A-8131234-5" />}

                </div>
            </div>
            {isAfkPopupVisible && (
                <div className="afk-popup">
                    <div className="afk-popup-content">
                        <h2>Вы всё ещё тут?</h2>
                        <p>Вы были неактивны некоторое время.</p>
                        <button onClick={hideAfkPopup}>Я тут</button>
                    </div>
                </div>
            )}

            {isAvailable == false ? (
                <div className="afk-popup">
                    <div className="afk-popup-content">
                        <h2>Этот контент недоступен</h2>
                        <p>Доступ может быть ограничен в связи с запросом правообладателя.</p>
                        <button onClick={() => navigate('/')}>Понятно :(</button>
                    </div>
                </div>
            ) : ""}
            <AppPromo />
            <Footer />
            <AdBlockWarn />
            {showSubscribePopup && (
                <AnimixPremiumPopup

                    onClose={() => { setShowSubscribePopup(false) }}
                />
            )}

        </div>
    );
};

export default TestWatchPage;

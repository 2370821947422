import React, { useState, useEffect } from 'react';
import './../App.css'; // Assuming you have a CSS file for styling
import logo from './../assets/logo_150.svg'; // Import logo.svg from the assets folder
import Header from './../components/Header';
import Footer from '../components/Footer';

const ContactPage = () => {
    const [scrollPosition, setScrollPosition] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            const position = window.pageYOffset;
            setScrollPosition(position);
        };

        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className="app">
            <Header scrolled={scrollPosition > 0} />
            <div className="content article">
                    <h1>Контакты</h1>
                    <p>
                        Для сотрудничества, выяснения спорных вопросов или претензий, направляйте электронное письмо
                        <br/><br/>
                        На e-mail: <br/><br/> obdristaev@gmail.com<br/>
                       
                     
                    </p>
                </div>
            <Footer />
        </div>
    );
};

export default ContactPage;

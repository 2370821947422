export default class DomainUtils {
    static getDomain(hostname = window.location.hostname, port = window.location.port) {
        if (hostname === "localhost" || port === '3000')
            return "animix.club";

        if (hostname === "")
            return "penis.news";

        return `${hostname}`;
    }
}

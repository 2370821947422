import React, { useEffect, useState } from 'react';
import './ProfileSettings.css'; // Assuming you have a CSS file for styling
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import ApiService from '../../services/ApiService';
import DomainUtils from '../../services/DomainUtils';
import ErrorCodes from '../../services/ErrorCodes';
import FramePopup from '../profile/FramePopup';
import ChangePassPopup from '../profile/ChangePassPopup';
import AnimixPremiumPopup from '../../components/premium/AnimixPremiumPopup';
import AnimixPremiumPopupCancel from "../../components/premium/AnimixPremiumPopupCancel";

const ProfileSettingsPage = () => {
    const [activeTab, setActiveTab] = useState('profile');
    const [description, setDescription] = useState('');
    const [nickname, setNickname] = useState('');
    const [selectedFrame, setSelectedFrame] = useState(null);
    const [telegram, setTelegram] = useState('');
    const [email, setEmail] = useState('');
    const [error, setError] = useState(null);
    const [showFramePopup, setShowFramePopup] = useState(false);
    const [showChangePassPopup, setShowChangePassPopup] = useState(false);
    const [showSubscribePopup, setShowSubscribePopup] = useState(false);
    const [showSubscribeCancelPopup, setShowSubscribeCancelPopup] = useState(false);
    const [passwordError, setPasswordError] = useState(null);
    const [frames, setFrames] = useState([]);
    const [allFrames, setAllFrames] = useState([]);
    const [account, setAccount] = useState(null);
    const [showEditPopup, setShowUploadPopup] = useState(false);
    const [frame, setFrame] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);

    const [dateSubscriptionPurchased, setDateSubscriptionPurchased] = useState(null);
    const [subscriptionType, setSubscriptionType] = useState('');
    const [autoSubscribe, setAutoSubscribe] = useState(false);
    const [subscribed, setSubscribed] = useState(false);

    const [hideSeriesPreviewEnabled, setHideSeriesPreview] = useState(localStorage.getItem("hideSeriesPreview") === "true");
    const [disableAutoSkip, setDisableAutoskip] = useState(localStorage.getItem("disableAutoSkip") === "true");



    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await ApiService.getSelf();

                if (data.type === "success") {
                    setAccount(data.data);
                    setNickname(data.data.nickname);
                    setDescription(data.data.description);
                    setSelectedFrame(data.data.selectedPicFrame);
                    setTelegram(data.data.telegram);
                    setDateSubscriptionPurchased(data.data.dateSubscriptionPurchased);
                    setSubscriptionType(data.data.subscriptionType);
                    setAutoSubscribe(data.data.autoSubscribe);
                    setSubscribed(data.data.subscribed);

                    const framesData = await ApiService.getFrames();
                    const availableFrameIds = [...data.data.availablePicFrames, 0];
                    const availableFrames = framesData.filter(frame => availableFrameIds.includes(frame.id));
                    setFrames(availableFrames);
                    setAllFrames(framesData);

                    const selectedFrameId = data.data.selectedPicFrame || localStorage.getItem("selectedPicFrame");
                    const selectedFrame = framesData.find(frame => frame.id == selectedFrameId);
                    if (selectedFrame) {
                        setFrame(selectedFrame);
                    }

                    const emailData = await ApiService.getEmail();
                    setEmail(emailData.data);
                }

                const getCheckoutData = await ApiService.getNotifications(0);
                console.log(JSON.stringify(getCheckoutData.data));
            } catch (error) {
                console.error('Error fetching profile data:', error);
            }
        };

        fetchData();
    }, []);

    const handleChangePass = async (newPassword) => {
        try {
            const response = await ApiService.changePassword(newPassword);
            if (response.type === "error") {
                setPasswordError(response);
            } else {
                setPasswordError(null);
                setShowChangePassPopup(false);
            }
        } catch (error) {
            setPasswordError('Error changing password');
            console.error('Error changing password:', error);
        }
    };

    const handleDescriptionChange = (event) => {
        setDescription(event.target.value);
    };

    const handleNicknameChange = (event) => {
        setNickname(event.target.value);
    };

    const handleTelegramChange = (event) => {
        setTelegram(event.target.value);
    };


    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };
    const handleUploadProfilePic = async (file) => {
        try {
            await ApiService.uploadProfilePic(file);
        } catch (error) {
            console.error('Error uploading profile picture:', error);
        }
    };

    const handleUploadBackground = async (file) => {
        try {
            await ApiService.uploadProfileBack(file);
        } catch (error) {
            console.error('Error uploading background image:', error);
        }
    };

    const handleSave = async () => {
        try {
            setSuccessMessage(null);
            const emailResponse = await ApiService.changeEmail(email);
            if (emailResponse.type === "error" | !email.includes("@") | email === null | email.length === 0) {
                setSuccessMessage("Ошибка: Введите корректную почту");
                return;
            }
            const response = await ApiService.editSelf({
                nickname,
                description,
                selectedPicFrame: selectedFrame,
                telegram
            });

            if (response.type === "error") {
                setError(response);
                setSuccessMessage("Ошибка: " + ErrorCodes.getText(response))
            } else {
                setError(null);
                setShowUploadPopup(false);
                setSuccessMessage("Изменения сохранены")

            }
        } catch (error) {
            console.error('Error saving profile:', error);
            setSuccessMessage("Ошибка (укажите почту)")
        }
    };

    const toggleAutoPay = async () => {
        try {
            const response = await ApiService.setAutopay(!autoSubscribe);

        } catch (error) {
            console.error('Error saving profile:', error);
        }
    };

    const handleFrameSelect = async (frame) => {
        setSelectedFrame(frame.id);
        setFrame(frame); // Ensure state updates correctly
        try {
            const response = await ApiService.editSelf({ nickname, description, selectedPicFrame: frame.id, telegram });
            if (response.type === "error") {
                setError(response);
            } else {
                setError(null);
                setShowUploadPopup(false);
            }
            setShowFramePopup(false);
        } catch (error) {
            console.error('Error selecting frame:', error);
        }
    };

    // Helper function to calculate next payment date
    const calculateNextPaymentDate = () => {
        if (!dateSubscriptionPurchased) return null;

        const purchaseDate = new Date(dateSubscriptionPurchased);
        let nextPaymentDate = new Date(purchaseDate);

        if (subscriptionType === 'YEARLY') {
            nextPaymentDate.setFullYear(nextPaymentDate.getFullYear() + 1);
        } else if (subscriptionType === 'MONTHLY') {
            nextPaymentDate.setMonth(nextPaymentDate.getMonth() + 1);
        }

        return nextPaymentDate.getTime();
    };

    // Helper function to format date in a readable format
    const formatDate = (timestamp) => {
        if (!timestamp) return '';

        const date = new Date(timestamp);
        return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
    };

    const renderContent = () => {
        switch (activeTab) {
            case 'player':
                return (
                    <div className="tab-content">
                        <h2>Плеер и просмотр</h2>
                        <label>Настройте плеер под себя</label>
                        <div className='horizontal-container-left'>

                            <input
                                type="checkbox"
                                id="myFrames"
                                name="myFrames"
                                checked={hideSeriesPreviewEnabled}
                                onChange={(e) => {
                                    setHideSeriesPreview(e.target.checked);
                                    localStorage.setItem("hideSeriesPreview", e.target.checked)
                                }}

                            />
                            <label className='label-switch' htmlFor="myFrames"></label>
                            <a>Скрывать превью серий</a>

                        </div>

                        <div className='horizontal-container-left'>

                            <input
                                type="checkbox"
                                id="myFrames2"
                                name="myFrames"
                                checked={disableAutoSkip}
                                onChange={(e) => {
                                    setDisableAutoskip(e.target.checked);
                                    localStorage.setItem("disableAutoSkip", e.target.checked)
                                }}

                            />
                            <label className='label-switch' htmlFor="myFrames2"></label>
                            <a>Выключить автопропуск</a>

                        </div>
                    </div>
                );
            case 'profile':
                return (
                    <div className="tab-content">
                        <h2>Информация профиля</h2>
                        <label>Изображение профиля</label>
                        <p className='settings-text-small'>Изображения меняются в течение суток</p>
                        <div className="upload-section">
                            <label htmlFor="profile-pic-upload" className="upload-label">
                                Сменить изображение (до 5 МБ)
                                <input id="profile-pic-upload" type="file" accept="image/*"
                                    onChange={(e) => handleUploadProfilePic(e.target.files[0])} />
                            </label>
                        </div>
                        <label>Рамка профиля</label>
                        <div className="profile-frame">
                            {frame && frame.url.length > 0 ? <img src={frame.url.replace("animix.lol", DomainUtils.getDomain())} alt="Нет рамки" /> : ""}
                            <button onClick={() => setShowFramePopup(true)}>Сменить</button>
                        </div>
                        <label>Фон профиля</label>
                        <div className="upload-section">
                            <label htmlFor="background-upload" className="upload-label">
                                Сменить фон (до 5 МБ)
                                <input id="background-upload" type="file" accept="image/*"
                                    onChange={(e) => handleUploadBackground(e.target.files[0])} />
                            </label>
                        </div>

                        <label>Никнейм</label>
                        <p className='settings-text-small'>Сменить никнейм можно только раз в три дня</p>
                        <input type="text" value={nickname} onChange={handleNicknameChange}
                            placeholder="Введите никнейм" />
                        <label>Описание</label>
                        <input type="text" value={description} onChange={handleDescriptionChange}
                            placeholder="Введите описание" />
                        <label>Ваш Telegram</label>
                        <p className='settings-text-small'>Обратите внимание, что ссылка должна вести на профиль или
                            канал в телеграм</p>
                        <input type="text" value={telegram} onChange={handleTelegramChange}
                            placeholder="https://t.me/ваш телеграм" />
                        <label>Почта</label>
                        <p className='settings-text-small'>Почта понадобится при восстановлении пароля или для отправки
                            чеков</p>
                        <input type="text" value={email} onChange={handleEmailChange} placeholder="mymail@animix.lol" />
                        <div></div>
                        <button onClick={handleSave}>Сохранить</button>
                        {successMessage != null ? <p>{successMessage}</p> : ""}
                    </div>
                );

            case 'subscription':
                const nextPaymentDate = calculateNextPaymentDate();

                return (
                    <div className="tab-content">
                        <h2>Подписка</h2>
                        {subscribed ?
                            <div>
                                <p>Ваша подписка активна.</p>

                                {autoSubscribe ? <p>Следующий платеж: {formatDate(nextPaymentDate)}</p> : ""}
                                <div>
                                    <button className='button-sec'
                                        onClick={() => {
                                            if (autoSubscribe) {
                                                setShowSubscribeCancelPopup(true);
                                            } else {
                                                toggleAutoPay();
                                                setAutoSubscribe(!autoSubscribe);
                                            }
                                        }}> {autoSubscribe ? 'Отключить подписку' : 'Подключить'}</button>
                                </div>
                            </div> : <div>
                                <p>Подписка даст Вам много возможностей и поддержит разработку платформы animix. Нажмите
                                    на кнопку ниже, чтобы узнать подробнее</p>
                                <div>
                                    <button className='button-sec'
                                        onClick={() => setShowSubscribePopup(true)}>
                                        Подключить подписку
                                    </button>
                                </div>
                            </div>}
                    </div>
                );

            case 'access':
                return (
                    <div className="tab-content">
                        <h2>Доступ</h2>
                        <p>Здесь вы можете управлять доступом к своему аккаунту</p>
                        <div>
                            <button className='button-sec' onClick={() => setShowChangePassPopup(true)}>Сменить пароль
                            </button>
                        </div>
                        <div style={{ height: 20 }}></div>
                        <div><a className='button-sec' href='/login'>Сменить аккаунт</a></div>
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <div className="app">
            <Header />
            <div className="content profile-settings">
                <div className="tabs">
                    <button className={activeTab === 'profile' ? 'active' : ''} onClick={() => setActiveTab('profile')}>
                        Профиль
                    </button>
                    <button className={activeTab === 'player' ? 'active' : ''} onClick={() => setActiveTab('player')}>
                        Плеер
                    </button>
                    <button className={activeTab === 'access' ? 'active' : ''} onClick={() => setActiveTab('access')}>
                        Доступ
                    </button>
                    <button className={activeTab === 'subscription' ? 'active' : ''}
                        onClick={() => setActiveTab('subscription')}>
                        Подписка
                    </button>
                </div>
                <div className="tab-content-wrapper">
                    {renderContent()}
                </div>
            </div>
            <Footer />
            {showFramePopup && (
                <FramePopup
                    availableFrames={frames}
                    allFrames={allFrames}
                    selectedFrame={selectedFrame}
                    handleFrameSelect={handleFrameSelect}
                    closePopup={() => setShowFramePopup(false)}
                />
            )}
            {showChangePassPopup && (
                <ChangePassPopup
                    handleChangePass={handleChangePass}
                    closePopup={() => setShowChangePassPopup(false)}
                    error={passwordError}
                />
            )}

            {showSubscribePopup && (
                <AnimixPremiumPopup
                    nickname={account.nickname}
                    onClose={() => {
                        setShowSubscribePopup(false)
                    }}
                />
            )}
         
            {showSubscribeCancelPopup && (
                <AnimixPremiumPopupCancel
                    onCancel={() => {
                        toggleAutoPay();
                        setAutoSubscribe(!autoSubscribe);
                        setShowSubscribeCancelPopup(false);
                    }}
                    onClose={() => {
                        setShowSubscribeCancelPopup(false);
                    }}
                />
            )}
        </div>
    );
};

export default ProfileSettingsPage;

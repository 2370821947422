import React, { useEffect, useRef, useState } from 'react';
import './../../App.css';
import './HomePage.css';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import homeVideo from '../../assets/home.mp4';
import AnimeCardComponent from '../../components/animecard/AnimeCardComponent';
import ApiService from '../../services/ApiService';
import WatchCardComponent from '../../components/watchcard/WatchCardComponent';
import telegram from '../../assets/telegram.png';
import YandexAdComponent from '../../components/YandexAdComponent';
import PlayIcon from '../../assets/ic_play.svg';
import mindIcon from '../../assets/ic_mind.svg';
import leaderboardIcon from '../../assets/ic_leaderboard.svg';
import collectionsIcon from '../../assets/ic_collection.svg';
import { useNavigate, useParams } from 'react-router-dom';
import AppPromo from '../../components/AppPromo';

import telegramIcon from "../../assets/telegramIcon.svg"
import server from "../../assets/serverIcon.svg"
import support from "../../assets/supportIcon.svg"
import RecommendationsComponent from '../../components/RecommendationsComponent';
import ProfilePic from '../../components/profilepic/ProfilePic';
import adTel from '../../assets/adTel.png';
import etycloud from '../../assets/etycloud.svg';
import AnimixPremiumPopup from '../../components/premium/AnimixPremiumPopup';

const HomePage = () => {
    const [scrollPosition, setScrollPosition] = useState(0);
    const [animeList, setAnimeList] = useState([]);
    const [watchList, setWatchList] = useState([]);
    const [recommendation, setRecommendation] = useState([]);
    const [categories, setCategories] = useState([]);
    const [url, setUrl] = useState(null);
    const [hideBanner, setHideBanner] = useState(false);
    const [loading, setLoading] = useState(true);
    const [showPremium, setShowPremium] = useState(false);
    const [loadingMore, setLoadingMore] = useState(false); // New state for loading more
    const [page, setPage] = useState(0); // New state for pagination
    const videoRef = useRef(null);
    const themes = [
        { name: 'null', colors: ['#111111', '#111111'] },
        { name: 'По умолчанию', colors: ['#111111', '#111111'] },
        { name: 'Магический остров', colors: ['#052029', '#160f22', '#131313', '#0e0e0e'] },
        { name: 'Конец Евангелиона', colors: ['#330305', '#1a170c', '#131313', '#0e0e0e'] },
        { name: 'Малиновый закат', colors: ['#1e0624', '#220c18', '#131313', '#0e0e0e'] },
        { name: 'Карибские глубины', colors: ['#061e24', '#0c2219', '#131313', '#0e0e0e'] },
        { name: 'Летающий замок', colors: ['#1d7085', '#13291f', '#131313', '#0e0e0e'] },
        { name: 'Ночь в Токио', colors: ['#1c023a', '#1d1329', '#131313', '#0e0e0e'] },


    ];

    // State to manage current theme
    const [currentTheme, setCurrentTheme] = useState(themes[0]);
    const [account, setAccount] = useState(null);

    const navigate = useNavigate();
    const sessionKey = localStorage.getItem('sessionKey');

    const isMobileDevice = window.innerHeight > window.innerWidth * 1.5;

    var isRecsLoad = false;
    // Load theme from localStorage on mount
    useEffect(() => {
        const savedThemeName = localStorage.getItem('theme');

        if (savedThemeName) {
            const selectedTheme = themes.find(theme => theme.name === savedThemeName);
            if (selectedTheme) {
                setCurrentTheme(selectedTheme);
            }
        }
    }, []);

    // Save theme name to localStorage when theme changes
    useEffect(() => {
        if (currentTheme.name !== "null") {
            localStorage.setItem('theme', currentTheme.name);
        }
    }, [currentTheme]);

    // Handle theme change on user interaction
    const handleThemeChange = (event) => {
        const selectedTheme = themes.find(theme => theme.name === event.target.value);
        if (selectedTheme && selectedTheme.name !== "null") {
            if (localStorage.getItem("subscribed") === "true") {
                setCurrentTheme(selectedTheme); // Update theme state
            }
            else {
                setShowPremium(true)
            }

        }
    };

    useEffect(() => {
        const loadData = async () => {
            try {
                const data = await ApiService.getSelf();
                if(data.type === "success")
                {
                    setAccount(data.data);
                    await fetchLastWatch();
                }
            }
            catch (e) { }
            await fetchPublic();


        };

        loadData();
    }, []);
    useEffect(() => {


        const loadData = async () => {

            setLoading(false);
            await fetchRecs(page); // Fetch initial recommendations
        };

        document.title = 'animix - Главная';
        const handleScroll = () => {
            const position = window.pageYOffset;
            setScrollPosition(position);

            // Detect if scrolled to the bottom of the page
            if (
                window.innerHeight + window.scrollY >= document.documentElement.scrollHeight - 100 &&
                !loadingMore
            ) {
                setLoadingMore(true); // Set loading more state
                setPage((prevPage) => prevPage + 1); // Increment page number
                fetchRecs(page + 1); // Fetch more recommendations
            }
        };

        window.addEventListener('scroll', handleScroll, { passive: true });
        loadData();

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [page, loadingMore]); // Add dependencies for page and loadingMore

    const fetchPublic = async () => {
        try {
            const data = await ApiService.getPublicAnimeList(0);
            if (data.type === "success") {
                setAnimeList(data.data);
            } else {
                console.error('Error fetching anime list:', data);
            }
        } catch (error) {
            // console.error('Error fetching data:', error);
        }

        // try {
        //     const catData = await ApiService.getPublicCategories();
        //     if (catData.type === "success") {
        //         setCategories(catData.data);
        //     }
        // } catch (error) {
        //     console.error('Error fetching data:', error);
        // }
    };

    const fetchLastWatch = async () => {
        try {
            const watchData = await ApiService.getLastWatch();
            if (watchData.type === "success") {
                setWatchList(watchData.data);
            }
        } catch (error) {
            //    console.error('Error fetching data:', error);
        }
    };

    const fetchRecs = async (page = 0) => {
        try {
            if (!isRecsLoad) {
                isRecsLoad = true;
                const recsArray = await ApiService.getRecs(page); // Pass page to API

                if (recsArray.type === "success") {
                    setRecommendation((prevRecs) => [
                        ...prevRecs,
                        recsArray.data, // Append new recommendations
                    ]);
                }
                setLoadingMore(false); // Reset loading state for more recommendations
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoadingMore(false);
        }
    };

    const handleLoad = () => {
        if (videoRef.current != null) {
            if (videoRef.current.currentTime < 50) {
                videoRef.current.currentTime = 50;
            }
        }
    };

    return (
        <div className="app">

            <Header scrolled={scrollPosition > 0} />

            <div className="home-main-hor-con">


                {!isMobileDevice ? (
                    <div className='home-menu'>
                        <div className='menu-item' onClick={() => { navigate("/leaderboard") }}>
                            <img src={leaderboardIcon} />
                            <a>Лидерборд</a>
                        </div>
                        {sessionKey ? <div className='menu-item' onClick={() => { navigate(`/collection/${localStorage.getItem("profileId")}`) }}>
                            <img src={collectionsIcon} />

                            <a>Моя коллекция</a>

                        </div> : ''}

                        {account != null ? <div >

                            <h3>Друзья в сети</h3>
                            <div className='friends-container'>
                                {(() => {
                                    // Filter online friends
                                    const onlineFriends = account.friendsEntities.filter(friend => {
                                        const lastWatchedTime = new Date(friend.lastWatchedTime);
                                        const currentTime = new Date();
                                        const differenceInMinutes = (currentTime - lastWatchedTime) / (1000 * 60);

                                        return differenceInMinutes <= 5;
                                    }).slice(0, 3); // Limit to 3 friends

                                    // Return JSX
                                    return (
                                        <div>
                                            {onlineFriends.length > 0 ? (
                                                onlineFriends.map((friend, index) => (
                                                    <div key={index}>
                                                        <a href={`/profile/${friend.nickname}`} className='friend'>
                                                            <ProfilePic isBig={false} account={friend} />
                                                            <p className="rec-anime-name">{friend.nickname}</p>
                                                        </a>
                                                    </div>
                                                ))
                                            ) : (
                                                <label style={{ margin: "30px 0" }}>Друзей в сети пока что нет</label>
                                            )}
                                        </div>
                                    );
                                })()}
                            </div>



                        </div> : <div>
                            <h3>Войдите в аккаунт</h3>
                            <label>И получите полный доступ</label>
                            <div style={{ margin: "30px 0" }}>
                                <a className="loginButton" href="/login">Войти</a>
                            </div>
                        </div>}

                        <a>Тема сайта</a>
                        <div className="theme-selector">

                            <select
                                id="theme-dropdown"
                                value={currentTheme.name}
                                onChange={handleThemeChange}
                            >
                                {themes.map((theme, index) => (
                                    (theme.name !== "null") ?
                                        <option key={index} value={theme.name}>
                                            {theme.name}
                                        </option> : ""
                                ))}
                            </select>
                        </div>
                        <a href='https://cloud.ety.one'>
                            <img style={{ width: 230 }} src={etycloud} />
                        </a>


                        <a className='fast-link' href="/contact">Контакты</a>
                        <a className='fast-link' href="/community">Правила сообщества</a>
                        <a className='fast-link' href="/legal">Правообладателям</a>
                        <a className='fast-link' href="/privacy">Политика конфиденциальности</a>

                        <a className='footerButton' href='https://t.me/animixlib'>
                            <img src={telegramIcon} /> Мы в Telegram
                        </a>
                    </div>
                ) : (
                    ""
                )}

                <div className='feed'>
                    {/* <div className='home-player-top'>
                        {url == null ? (
                            <video key={homeVideo} autoPlay loop muted>
                                <source src={homeVideo} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        ) : (
                            <video key={url} ref={videoRef} onPlay={handleLoad} autoPlay loop muted>
                                <source src={url} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        )}

                        <div className='home-overlay'>
                            <div className="home-title">
                                <h1 className="title">Re:Zero</h1>
                                <p>Третий сезон популярнейшего аниме уже доступен</p>
                            </div>
                            <a href={`/anime/1307`} className='actionButton playButton head'>
                                <img src={PlayIcon} alt="Play Icon" className="playIcon" />Смотреть
                            </a>
                        </div>
                    </div> */}

                    <div className="full-width-da-block">
                        <img src={adTel} alt="Ad Background" />
                        <h2 className="da-title">Анимикс закрывается.</h2>
                        <p className="da-text">Шутка! А если сайт перестанет работать, вы всегда сможете найти нас в Телеграме</p>
                        <label className="da-text2">пожалуст нажми на кнопку</label>
                        <button className="da-button" onClick={() => window.open("https://t.me/animixlib")}>
                            Мы всегда будем вместе
                        </button>
                    </div>

                    <div className='animeblock'>
                        {loading ? (
                            <div className="loader"></div> // Show loader while loading
                        ) : (
                            <>
                                {watchList.length > 0 && (
                                    <div>
                                        <h1>Продолжить просмотр</h1>
                                        <div className="watch-container">
                                            {watchList.map((watchSnippet) => (
                                                <WatchCardComponent key={watchSnippet.id} watchSnippet={watchSnippet} isInteractive={true} />
                                            ))}
                                        </div>
                                    </div>
                                )}
                                {localStorage.getItem("subscribed") == true ? "" : (
                                    <YandexAdComponent blockId={localStorage.getItem("subscribed") ? `` : `R-A-8131234-1`} />
                                )}
                                <a className="container-wrapper"  >
                                    <h1 className='anime-title'>Всё аниме</h1>
                                    <div className="anime-line-container-padding">
                                        <div className="anime-line-container">
                                            {animeList.map((anime) => (
                                                <AnimeCardComponent key={anime.id} anime={anime} isInteractive={true} />
                                            ))}
                                        </div>
                                        <a href='/all' className="view-all-button">Посмотреть все</a>
                                    </div>
                                </a>
                                {localStorage.getItem("subscribed") == true ? "" : <YandexAdComponent blockId="R-A-8131234-2" />}
                                {recommendation.map((rec, index) => (
                                    <RecommendationsComponent key={index} recs={rec} />
                                ))}
                                {loadingMore && <div className="loader"></div>} {/* Show loader when loading more */}
                            </>
                        )}
                    </div>
                </div>
            </div>
            <div style={{
                background: `linear-gradient(135deg, ${currentTheme.colors.join(', ')})`,




                position: "fixed",
                top: 0,
                zIndex: -1,
                left: 0,

                width: "100vw",
                height: "100vh"

            }} />
            <AppPromo />
            <Footer />
            {showPremium ? <AnimixPremiumPopup onClose={() => {
                setShowPremium(false);
            }} /> : ""}
        </div>
    );
};

export default HomePage;

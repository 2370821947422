import React, { useState, useCallback } from 'react';
import ProfilePic from "../components/profilepic/ProfilePic";
import "./ReviewComponent.css";
import premium from '../assets/premium_badge.svg';
import trashIcon from '../assets/ic_trash.svg';
import ApiService from '../services/ApiService';
import DomainUtils from '../services/DomainUtils';
import placeholder from '../assets/placeholder.png';
import { useNavigate, useParams } from 'react-router-dom';

const ReviewComponent = ({ review, isReview = true, deletable = false, openNewPage = false, }) => {
  const [rating, setRating] = useState(review.stars);
  const [deleted, setDeleted] = useState(false);
  const navigate = useNavigate();


  const formatDate = useCallback((milliseconds) => {
    const months = [
      'января', 'февраля', 'марта', 'апреля', 'мая', 'июня',
      'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'
    ];

    const date = new Date(milliseconds);
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();

    return `${day} ${months[monthIndex]} ${year}`;
  }, []);

  const handleDeleteComment = useCallback(async () => {
    try {
      const response = await ApiService.deleteComment(review.id);
      if (response.type === 'success') {
        setDeleted(true);
      } else {
        console.error('Failed to delete comment:', response.message);
      }
    } catch (error) {
      console.error('Error deleting comment:', error);
    }
  }, [review.id]);

  const handleNavigateToProfile = useCallback(() => {

    
    if (openNewPage) {
      const BASE_DOMAIN = window.location.hostname === 'localhost'
        ? 'https://animix.lol'
        : `https://${DomainUtils.getDomain()}`;

      window.open(`${BASE_DOMAIN}/profile/${review.user.nickname}`, '_blank');

    }
    else {

      navigate(`/profile/${review.user.nickname}`);
    }
  }, [navigate, review.user.nickname]);
 const API_DOMAIN = `https://api.${DomainUtils.getDomain()}`

const profileBackgroundUrl = `${API_DOMAIN}/account/getProfileData/${review.user.id}_profile_background.png`;
  return (
    <div className={`review ${review.user.subscribed ? "sub" : ""} ${deleted ? "deleted" : ""}`}>
      <div>
        <div className='review-con'>
          {isReview && (
            <div className='star-con'>
              <a className={"star"}>★</a>
              <span className="stars-count">{rating}</span>
            </div>
          )}
          <p className="review-date" style={!isReview ? { margin: 0 } : {}}>
            {formatDate(review.date)}
          </p>
        </div>

        <p className='review-message'>
          {review.user.banned ? "Содержимое недоступно" : review.message}
        </p>

        <div className='review-user-section'>
          <a style={{ cursor: "pointer" }} className='review-user-section' onClick={handleNavigateToProfile}>
            <ProfilePic isBig={false} account={review.user} />
            <p className='review-p'>
              {review.user.banned ? "Аккаунт недоступен" : review.user.nickname}
              {review.user.subscribed && (
                <img style={{ height: 24, verticalAlign: 'middle' }} src={premium} alt="Premium Badge" />
              )}
            </p>
          </a>
          {deletable && !deleted && (
            <img
              style={{
                height: 40,
                display: "inline-block",
                paddingBottom: 5,
                verticalAlign: 'middle',
                cursor: "pointer"
              }}
              src={trashIcon}
              alt="Delete Icon"
              onClick={handleDeleteComment}
            />
          )}
        </div>
      </div>

      {review.user.subscribed && (
        <img
          style={{ opacity: 0.1 }}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = placeholder;
          }}
          className="background-image"
          src={profileBackgroundUrl}
          alt="Profile Background"
        />
      )}
    </div>
  );
};

export default ReviewComponent;

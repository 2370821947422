import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import placeholder from "../../assets/card_placeholder.png";
import fire from "../../assets/ic_fire.svg";
import diamond from "../../assets/ic_diamond.svg";
import DomainUtils from '../../services/DomainUtils';
import './AnimeCardComponent.css';

const AnimeCardComponent = ({ anime, isInteractive = false }) => {
    const [isHovered, setIsHovered] = useState(false);
    const [imageLoaded, setImageLoaded] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const [imageSrc, setImageSrc] = useState(placeholder); // Start with placeholder
    const navigate = useNavigate();
    const cardRef = useRef();

    // Load image asynchronously to handle success or failure
    useEffect(() => {
        if (anime && anime.id) {
            const img = new Image();
            const API_DOMAIN = window.location.hostname === 'localhost' 
    ? 'https://api.animix.club' 
    : `https://api.${DomainUtils.getDomain()}`;

const animeImageUrl = `${API_DOMAIN}/anime/getThumb/${anime.id}_thumb.png`;


            img.src = animeImageUrl;
            img.onload = () => {
                setImageSrc(animeImageUrl); // Set to the anime image when loaded
                setImageLoaded(true);
            };
            img.onerror = () => {
                setImageSrc(placeholder); // Fallback to placeholder if error occurs
                setImageLoaded(true);
            };
        }
    }, [anime]);

    const handleMouseEnter = () => {
        if (!isInteractive) return;
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        if (!isInteractive) return;
        setIsHovered(false);
    };

    const handleWatchButtonClick = () => {
        navigate(`/anime/${anime.id}`);
    };

    const description = () => {
        if (!anime || anime.description === undefined || anime.description === null) return;
        return anime.description.length > 80
            ? anime.description.substring(0, 80) + "..."
            : anime.description;
    };

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setIsVisible(true);
                        observer.unobserve(entry.target);
                    }
                });
            },
            { threshold: 0.1 }
        );

        if (cardRef.current) {
            observer.observe(cardRef.current);
        }

        return () => {
            if (cardRef.current) {
                observer.unobserve(cardRef.current);
            }
        };
    }, []);

    return (
        <a
            className="anime-card"
            onClick={() => {navigate("/anime/" + anime.id)}}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            ref={cardRef}
        >

            <img
                src={imageSrc}
                className={`anime-thumbnail ${isHovered ? 'hovered' : ''} ${imageLoaded ? 'loaded' : ''}`}
            />
                 {anime.hq ? <img className={`hq  ${isHovered ? 'hovered' : ''} `} src={diamond} alt="High quality" /> : ''}
            {isInteractive ? (
                <div className="anime-info">
                    <div className='anime-text-container'>
                        <h3 className="anime-title-card">{anime.name}</h3>
                        <p className='anime-description'>{description()}</p>
                    </div>
                    <button className='button-sec watch-button' onClick={handleWatchButtonClick}>
                        Смотреть
                    </button>
                </div>
            ) : null}

           
            <div className='badges'>
                {anime.viral > 10 ? <img className={`viral`} src={fire} alt="viral" /> : ''}
                {anime.rating !== 0 ? <h4 className={`rating ${anime.rating >= 7.5 ? anime.rating >= 9 ? "premium" : "good" : ""}`}>★ {anime.rating}</h4> : ''}
            </div>

        </a>
    );
};

export default AnimeCardComponent;

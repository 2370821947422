import axios from 'axios';
import ApiService from './ApiService';
import DomainUtils from './DomainUtils';

const API_URL = 'https://api.animix.lol';
const CDN_URL = 'https://cdn.animix.lol';


const ContentService = {

    fetchVideo: async (privateId) => {

        try {

            const optimalServerData = await ApiService.getOptimalServer(privateId);
     

            console.log("obtaining watch url")
           
            const API_DOMAIN = window.location.hostname === 'localhost'
            ? 'https://cdn.animix.lol'
            : `https://cdn.${DomainUtils.getDomain()}`;
            console.log("set watch url")
            if (optimalServerData.data === "no-cache") {
               return `${API_DOMAIN}/content/directWatch?privateId=` + privateId;
            }
            else {
                 
                const animeData = await ApiService.getWatchSession(privateId, optimalServerData.data.replace("animix.lol", DomainUtils.getDomain()));
                return (optimalServerData.data.replace("animix.lol", DomainUtils.getDomain()) + "content/watch/" + animeData.data);
            }


        } catch (error) {
            console.error('Error fetching anime list:', error);
        }
    }

}
export default ContentService;
